@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #e4e4e2;
  font-weight: 200;
  font-style: normal;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.logo {
  width: 200px;
  margin-bottom: 1rem;
}

.description {
  font-weight: 300;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 1.8;
}

.description b {
  font-weight: 600;
}

.language-switch {
  margin-bottom: 2rem;
}

.language-switch button {
  background: transparent;
  border: none;
  color: #333;
  margin: 0 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
}

.language-switch button.active {
  background: #333;
  color: #fff;
}

.language-switch button:hover {
  text-decoration: underline;
}

.contact {
  font-size: 1rem;
  margin-top: 1rem;
}

.contact a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

.contact a:hover {
  text-decoration: underline;
}

.footer {
  text-align: center;
  padding: 1rem;
  background-color: #c4c4c4;
  color: #333;
  font-size: 0.9rem;
}

/* general styles */

.scroller {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent items from stacking vertically */
  white-space: nowrap; /* Keep items in a single row */
  scroll-behavior: smooth; /* Smooth scrolling */
  padding: 10px;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  white-space: nowrap;
}

.scroller__inner {
  display: flex;
  flex-wrap: nowrap; /* Ensure no wrapping of items */
  align-items: center; /* Center items vertically */
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 80s) var(--_animation-direction, forwards) linear infinite;
}

.scroller__inner img {
  width: auto;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  aspect-ratio: 3 / 2;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  margin-right: 20px;
}

.scroller__inner img:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 10s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 160s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
